import { URL_SERVER } from '../configs/urlConfig';
import { authHeader, handleResponse } from '../_helpers';

export const registerService = {
    registerUserClient
}

function registerUserClient(agencyId, userName, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            agencyId: agencyId,
            password: password,
            userName: userName
        })
    };
    return fetch(`${URL_SERVER}/api/v1/Register/RegisterUserClientAsAgency`, requestOptions).then(handleResponse);
}
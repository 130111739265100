import { createSlice } from '@reduxjs/toolkit';

export const accountReducer = createSlice({
    name: 'account',
    initialState: {
        token: null,
        user: null,
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const { setToken, setUser } = accountReducer.actions;

export default accountReducer.reducer;

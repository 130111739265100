import { URL_SERVER } from '../configs/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const financingService = {
    getFinancingItems,
    getFinancingItem,
    payFinancingItem
}

function getFinancingItems(clientId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/Financing/ListFinancingItems/${clientId}`, requestOptions).then(handleResponse);
}

function getFinancingItem(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/Financing/GetFinancingItem/${id}`, requestOptions).then(handleResponse);
}

function payFinancingItem(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            FinancingItemId: id
        })
    };
    return fetch(`${URL_SERVER}/api/v1/Financing/PayFinancingItem`, requestOptions).then(handleResponse);
}
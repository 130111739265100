import { URL_SERVER } from '../configs/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const paymentCardService = {
    getPaymentCards,
    createPaymentCard,
    deleteCreditcard
}

function getPaymentCards(clientId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/PaymentCard/all/${clientId}`, requestOptions).then(handleResponse);
}

function createPaymentCard(firstName, lastName, billingAddress, state, city, countryIso2, zipCode, billingPhone, type, number, cvv, expiration, clientId) {
    const model = {
        firstName: firstName,
        lastName: lastName,
        billingAddress: billingAddress,
        state: state,
        city: city,
        countryIso2: countryIso2,
        zipCode: zipCode,
        billingPhone: billingPhone,
        type: type,
        number: number,
        cvv: cvv,
        expiration: expiration,
        customerId: clientId
    }
    const header = authHeaderTypeJson();
    const requestOptions = { method: 'POST', headers: authHeaderTypeJson(), body: JSON.stringify(model) };
    return fetch(`${URL_SERVER}/api/v1/PaymentCard/create`, requestOptions).then(handleResponse);
}

function deleteCreditcard(id) {
    const requestOptions = { method: 'DELETE', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/PaymentCard/delete/${id}`, requestOptions).then(handleResponse);
}

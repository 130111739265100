import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import layoutReducer from './features/reducers/LayoutReducer'
import accountReducer from './features/reducers/AccountReducer'

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    account: accountReducer,
  },
});

/*
const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store*/
import React, { useState, useEffect } from 'react'
import { URL_SERVER } from "../../../configs/urlConfig";
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { Page, View, Document, Image, Text, Link } from '@react-pdf/renderer';
import { authHeader } from 'src/_helpers';

const InvoicePdf = ({ match }) => {
    const { id } = match.params
    const [invoice, setInvoice] = useState(null);
    let history = useHistory();

    useEffect(() => {
        async function fetchData() {
            setInvoice(await getInvoice(id));
        }
        fetchData();
    }, []);

    const getInvoice = async (id) => {
        var response = await axios.get(URL_SERVER + '/api/v1/Invoice/Get?id=' + id, {
            headers: authHeader()
        })
            .then((res) => {
                if (res.status == 200) {
                    return res.data;
                }
                else {
                    alert(res.error);
                    /*addToast(res.error, {
                        appearance: 'info',
                        autoDismiss: true,
                    });*/
                    return null;
                }
            })
            .catch((error) => {
                //alert("No se han podido obtener los datos del pago")
                /*addToast("No se han podido obtener los datos del pago", {
                    appearance: 'erro',
                    autoDismiss: true,
                });*/
                return null;
            });

        return response;
    }

    return (
        <Document>
            <Page>
                <View>
                    <View>
                        <View className="d-flex justify-content-center border border-dark Text-3">
                            <Image src={'images/invoice/lalianxa.png'} width={200} />
                        </View>
                        <View>
                            <Text className='bold'>CREDIT CARD AUTHORIZATION FORM-CANCELLATION TERMS AND DISCLOSURES </Text>
                            <Text>
                                I, <Text className='pr-1'>{invoice?.paymentInformation?.name}</Text>  hereby authorize CTG LALIANXA TRAVEL GROUP and/or my travel
                                agency <Text className='pr-1'>{invoice?.paymentLink?.client?.agency?.name}</Text> to charge my credit card in the amount of
                                $ <Text className='pr-1'>{invoice?.paymentLink?.amount + invoice?.paymentLink?.serviceCost}</Text>
                                for a vacation package/travel reservation purchase by me, which might include other passengers, traveling to
                                (Destination)_______________________________________ on (Date if Travel)___________________________    with Reservation #__________________________________   _________________________________________
                            </Text>
                        </View>
                        <View className="border border-dark Text-1">
                            <Text><Text>Credit Card Information:</Text></Text>
                            <Text>Name/Credit Card Holder: {invoice?.paymentInformation?.name}</Text>
                            <Text>Credit Card # : <Text>{invoice?.paymentInformation?.cardNumber}</Text> Expiration Date: <Text>{invoice?.paymentInformation?.expireMonth}/{invoice?.paymentInformation?.expireYear}</Text> VCC:<Text>{invoice?.paymentInformation?.cvv}</Text> Card Type:______________ </Text>
                            <Text>Billing Address: <Text>{invoice?.paymentInformation?.address}</Text> </Text>
                            <Text>City: <Text>{invoice?.paymentInformation?.city}</Text> State: <Text>{invoice?.paymentInformation?.state}</Text> ZIP: {<Text>{invoice?.paymentInformation?.zip}</Text>}  Phone:_____________________ </Text>
                            <Text>E-Mail Address: <Text>{invoice?.paymentInformation?.email}</Text> Cell Phone:________________________________</Text>
                            <Text>Card Holder’s Signature: <Image src={invoice?.paymentInformation?.signatureBase64} width={100} /> Date:_______________________________ </Text>
                        </View>

                        <View>
                            <Text><Text><Text>TERMS AND CONDITION: </Text></Text></Text>
                            <Text>
                                • Initial Deposit (Non-Refundable) per person at time of booking: Adults $100 / Child $100. <Text />
                                • Second Deposit: 30 days after booking day: Adults $200/ Child $200 <Text />
                                • Third Deposit: 60 days after booking day: Adults $200/ Child $200 <Text />
                                • Final Payment: 60 days prior to departure. <Text />
                                • Changes fee: $100 <Text />
                                • Cancellation Policy:  1. ALL Deposits are Non-Refundable. 2. 50% of total price, except the deposits is, refundable 59 to 46 days prior to departure. 3. Full penalty (No Refund) 45 days prior to departure. <Text />
                                • Each passenger has the responsibility to have all the proper documents for travel. <Text />
                                • U. S. Citizens and/or U.S. Residents are required to have a valid U.S. travel document. <Text />
                                • Non-US citizens need a valid passport from their country of origin and any valid visa to enter the United  States via airline transportation.

                            </Text>
                        </View>
                        <View>
                            <Text><Text><Text>READ AND INITIAL EACH OF THE FOLLOWING: </Text></Text></Text>
                            <Text>
                                ______I agree that payment has been processed by CTG LALIANXA TRAVEL GROUP, LLC  and cancellation terms and policies apply to the vacation package/travel reservation I purchased. <Text />
                                ______I agree the total cost may include charges from my travel agency/and or CTG LALIANXA TRAVEL GROUP,LLC for all service charges and taxes. <Text />
                                ______I have read and accept CTG LALIANXA TRAVEL GROUP,LLC  Terms and Conditions listed above. Full Cancellation Policies and Terms and Conditions are available at the following link: <Link href="http://traveltecsolutions.com/CTGTerms.pdf"> http://traveltecsolutions.com/CTGTerms.pdf</Link>
                            </Text>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    )
}


export { InvoicePdf };
import React, { useState, useEffect } from 'react'
import { URL_SERVER } from "../../../configs/urlConfig";
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { freeSet } from '@coreui/icons'
import {
    CCol,
    CRow,
    CButton,
    CFormGroup,
    CLabel,
    CSelect,
    CInput,
    CContainer,
    CInputCheckbox,
    CLink
} from '@coreui/react'
import CanvasDraw from "react-canvas-draw";
import CIcon from '@coreui/icons-react';
import SignatureCanvas from 'react-signature-canvas'
import { PaymentType } from 'src/enums/Enums';

const Pay = ({ match }) => {
    let history = useHistory();
    const { id } = match.params
    const [paymentLink, setPaymentLink] = useState(null);
    const [hideDescription, setHideDescription] = useState(false);
    const [hidePayment, setHidePayment] = useState(true);
    const [form, setForm] = useState({
        paymentLinkId: '',
        signatureBase64: null,
        name: '',
        email: '',
        state: '',
        city: '',
        address: '',
        zip: '',
        cardNumber: '',
        expireMonth: '',
        expireYear: '',
        cvv: '',
    });
    const signCanvas = React.createRef();
    const [autohide, setAutohide] = useState(true)
    const [errors, setErrors] = useState({})
    const [blockUI, setBlockUI] = useState(false)

    var sigPad = {};
    const clear = () => {
        sigPad.clear()
    }

    React.useEffect(async () => {
        init();
    }, []);

    const init = async () => {
        var data = await getPaymentLink(id);
        if (data == null)
            history.push("/404");
        if (data && data.wasPayed)
            history.push("/payed");
        await setForm({
            ...form,
            ['paymentLinkId']: data.id
        })
        setPaymentLink(data);
    }

    const getPaymentLink = async (token) => {
        var response = await axios.get(URL_SERVER + '/api/v1/PaymentLink/GetBytoken?token=' + token)
            .then((res) => {
                if (res.status == 200) {
                    return res.data;
                }
                else {
                    alert(res.error);
                    /*addToast(res.error, {
                        appearance: 'info',
                        autoDismiss: true,
                    });*/
                    return null;
                }
            })
            .catch((error) => {
                //alert("No se han podido obtener los datos del pago")
                /*addToast("No se han podido obtener los datos del pago", {
                    appearance: 'erro',
                    autoDismiss: true,
                });*/
                return null;
            });

        return response;
    }
    const handleChange = async e => {
        const { name, value } = e.target;
        await setForm({
            ...form,
            [name]: value
        });
    }

    const validateForm = () => {
        setErrors({});
        let err = {}
        let isValid = true;

        if (paymentLink?.paymentType == 0) {
            if (!form.name) {
                isValid = false;
                err['name'] = 'Please enter your credit card name.'

            }
            if (!form.cardNumber) {
                isValid = false;
                err['cardNumber'] = 'Please enter your card number.'
            }
            if (!form.expireMonth) {
                isValid = false;
                err['expireMonth'] = 'The expire month is required.'
            }
            if (form.expireMonth != '' && form.expireMonth != undefined) {
                if (parseInt(form.expireMonth) > 12) {
                    isValid = false;
                    err['expireMonth'] = 'The expire month is not valid.'
                }
            }
            if (!form.expireYear) {
                isValid = false;
                err['expireYear'] = 'The expire year is required.'
            }
            if (!form.cvv) {
                isValid = false;
                err['cvv'] = 'The cvv is required.'
            }

            if (!form.address) {
                isValid = false;
                err['address'] = 'Please enter your address.'
            }
            if (!form.state) {
                isValid = false;
                err['state'] = 'Please enter your state.'
            }
            if (!form.city) {
                isValid = false;
                err['city'] = 'Please enter your city.'
            }
            if (!form.zip) {
                isValid = false;
                err['zip'] = 'Please enter your zip.'
            }
            if (!form.email) {
                isValid = false;
                err['email'] = 'Please enter your email.'
            }
            if (typeof form.email !== "") {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(form.email)) {
                    isValid = false;
                    err['email'] = 'Please enter valid email address.'
                }
            }
        }


        if (!form.signatureBase64) {
            isValid = false;
            err['signatureBase64'] = 'The signature is required.'
        }
        setErrors(err);
        return isValid;
    }

    const pay = async () => {
        setBlockUI(true);
        if (!validateForm()) {
            setBlockUI(false);
            return false;
        }
        axios.post(
            URL_SERVER + "/api/v1/Invoice/Create", form)
            .then((res) => {
                if (res.status == 200) {
                    if (res.data.isSuccess) {
                        history.push("/pay_success");
                    }
                    else {
                        setBlockUI(false);
                        alert(res.data.errorMessage);
                        /*addToast(res.data.errorMessage, {
                            appearance: 'error',
                            autoDismiss: true,
                        })*/
                    }
                }
                else {
                    setBlockUI(false);
                    alert("No se ha podido crear el pago")
                    /*addToast(res.data.Error, {
                        appearance: 'error',
                        autoDismiss: true,
                    })*/
                }
            })
            .catch((error) => {
                setBlockUI(false);
                alert("No se ha podido crear el pago")
                /*addToast("No se ha podido crear el pago", {
                    appearance: 'error',
                    autoDismiss: true,
                });*/
                console.error(error)
            })
    }

    const createSignImg = async () => {
        /*setForm({
            ...form,
            ['signatureBase64']: signCanvas.current.canvas.drawing.toDataURL('image/png')
        })*/
        setForm({
            ...form,
            ['signatureBase64']: sigPad.getTrimmedCanvas().toDataURL('image/png')
        })
    };

    return (
        <BlockUi tag="div" blocking={blockUI}>
            <CContainer className='mt-3' >
                <CContainer className={hideDescription && 'd-none'}>
                    <CContainer className='text-center'>
                        <CRow>
                            <CCol md='12' xs='12' sm='12'>
                                <h3>Hello, <b>{paymentLink && paymentLink.client.agency.name}</b> </h3>
                            </CCol>
                            <CCol md='12' xs='12' sm='12'>
                                <h4>payment request #{paymentLink && paymentLink.invoiceNumber}</h4>
                            </CCol>

                        </CRow>
                    </CContainer>
                    <CContainer className='text-center mt-5'>
                        <CRow>
                            <CCol md='12' xs='12' sm='12'>
                                <h6><b>Pay Details: </b></h6>
                                <h6>Amount: ${paymentLink && paymentLink.amount} usd</h6>
                                <h6>Fee Service: ${paymentLink && paymentLink.serviceCost} %</h6>
                                <h5 className='text-primary'>Total: ${paymentLink && paymentLink.getTotal} USD</h5>
                            </CCol>
                        </CRow>
                    </CContainer>
                    <CContainer className='mb-5 mt-5'>
                        {paymentLink?.client?.agency?.name == 'Kekas Travel' ?
                            <>
                                <label><b>TERMS AND CONDITIONS</b></label>
                                <label>Please read carefully</label>
                                <ul>
                                    <li>All air purchases have restrictions based on the category of fare purchased. The client accepts and agrees that he/she was informed of these restrictions based on their purchase.</li>
                                    <li>All package/tour purchases have restrictions and or penalties for cancellations or changes made. The client accepts and agrees that he/she was informed of these restrictions based on their purchase.</li>
                                    <li>Kekas Travel Agency is not responsible for airline delays, cancelations, or schedule changes.  The client is responsible for re-confirming their flights 24hrs prior to departure.</li>
                                    <li>Penalties and refunds will be subjected to Supplier’s regulations.</li>
                                    <li>Cancellations MUST be phoned into Keka’s Travel Agency at 305-639-3408</li>
                                    <li>No refunds will be made in the event of a “no show" or “trip interruption”.</li>
                                    <li>Keka’s Travel Agency strongly recommend that you purchase travel insurance.</li>
                                    <li>The client accepts and agrees that all this information, regulations and restrictions were provided prior to purchase.</li>
                                    <li>Client accepts and agrees to amount being charged on credit card.</li>
                                </ul>
                            </>

                            :
                            <ul>
                                <li><b>{paymentLink?.client?.agency?.name}</b> informo las desventajas que caracterizan a los vuelos charter y se le explico las ventajas de usar vuelo comercial ejemplo de Latam Airlines.</li>
                                <li>El cliente acepta que fue informado de las diferencias en el uso de charter y Líneas Comerciales.</li>
                                <li><b>{paymentLink?.client?.agency?.name}</b> no se hace responsable por retrasos, cancelaciones, reprogramaciones, no shows, penalidades como consecuencias de las mencionadas situaciones en vuelos la cual también podrían traer cambios y perdidas de noches en reservas de Hoteles y otros servicios turísticos que forman parte de su paquete.</li>
                                <li>El cliente acepta y declara que fue informado que los vuelos charters se caracterizan por presentar demoras, cancelaciones y reprogramaciones en sus horarios de vuelos.</li>
                                <li>El Cliente acepto que <b>{paymentLink?.client?.agency?.name}</b> me informo la diferencia entre vuelo Charter y Comercial.</li>
                                <li>El Cliente acepta y declara que recibió la debida información antes de compras su vuelo.</li>
                                <li>El cliente acepta y declara que tomo su decisión de compra Libremente y sin coacción alguna.</li>
                                <li>El cliente entiende y acepta que <b>{paymentLink?.client?.agency?.name}</b> es un canal de ventas e intermediario entre las aerolíneas , hoteles , productos en general y el cliente final.</li>
                            </ul>
                        }


                        <CContainer className='ml-2'>

                            <CInputCheckbox
                                id="autohide"
                                checked={autohide}
                                onChange={e => { setAutohide(e.target.checked) }}
                            />
                            {paymentLink?.client?.agency?.name == 'Kekas Travel' ?
                                <CLabel >Accept Terms and Conditions</CLabel>
                                :
                                <CLabel >Accept <CLink target='_blank' href="https://86i.604.myftpupload.com/terminos-y-condiciones/">Terms and Conditions</CLink></CLabel>
                            }
                        </CContainer>

                    </CContainer>
                    <CRow >
                        <CCol md='12' xs='12' sm='12'>
                            <button hidden={!autohide} type="button" class="btn  btn-pill btn-behance btn-block" onClick={() => { setHideDescription(true); setHidePayment(false); }}><span class="cib-behance btn-icon mr-2"></span> Proceed to payment</button>
                        </CCol>
                    </CRow>
                </CContainer>
                <CContainer className={hidePayment && 'd-none'}>
                    <CRow>
                        <CCol sm='12' md='12' xs='12'><h3>Please enter the details for payment.</h3></CCol>
                        {paymentLink?.paymentType == 0 &&
                            <>
                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Credit Card Name</CLabel>
                                        <CInput type="text" placeholder="Your Credit Card Name" value={form.name} name="name" onChange={handleChange} required />
                                        <div className="text-danger">{errors.name}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Credit Card Number</CLabel>
                                        <CInput type="text" placeholder="Credit Card Number" value={form.cardNumber} name="cardNumber" onChange={handleChange} required />
                                        <div className="text-danger">{errors.cardNumber}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='4' sm='4' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Expire Month</CLabel>
                                        <NumberFormat className='form-control' format='##' placeholder='00' value={form.expireMonth} name="expireMonth" onChange={handleChange} />
                                        <div className="text-danger">{errors.expireMonth}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='4' sm='4' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Expire Year</CLabel>
                                        <NumberFormat className='form-control' format='##' placeholder='00' value={form.expireYear} name="expireYear" onChange={handleChange} />
                                        <div className="text-danger">{errors.expireYear}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='4' sm='4' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>CVV</CLabel>
                                        <NumberFormat className='form-control' format='####' placeholder='0000' value={form.cvv} name="cvv" onChange={handleChange} />
                                        <div className="text-danger">{errors.cvv}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Address</CLabel>
                                        <CInput type="text" placeholder="Address" value={form.address} name="address" onChange={handleChange} required />
                                        <div className="text-danger">{errors.address}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>State</CLabel>
                                        <CInput type="text" placeholder="State" value={form.state} name="state" onChange={handleChange} required />
                                        <div className="text-danger">{errors.state}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>City</CLabel>
                                        <CInput type="text" placeholder="City" value={form.city} name="city" onChange={handleChange} required />
                                        <div className="text-danger">{errors.city}</div>
                                    </CFormGroup>
                                </CCol>

                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Zip</CLabel>
                                        <NumberFormat className='form-control' format='#####' placeholder='00000' value={form.zip} name="zip" onChange={handleChange} />
                                        <div className="text-danger">{errors.zip}</div>
                                    </CFormGroup>
                                </CCol>
                                <CCol xs='12' sm='12' md='6' lg='3'>
                                    <CFormGroup>
                                        <CLabel>Email</CLabel>
                                        <CInput type="email" placeholder="Email" value={form.email} name="email" onChange={handleChange} required />
                                        <div className="text-danger">{errors.email}</div>
                                    </CFormGroup>
                                </CCol>
                            </>}
                    </CRow>
                    <CRow>
                        <CCol md='12' xs='12' sm='12' className='mb-3'>
                            <CRow>
                                <CCol md='8' xs='8' className='pt-2'>
                                    <h4>Please sign here: </h4>
                                </CCol>
                                <CCol md='4' xs='4' className='text-right'>
                                    <CButton type="button" className='btn btn-info mb-1 btn-sm' onClick={clear}>
                                        <CIcon color="white" className="text-white" size='lg' content={freeSet.cilBrush} />
                                        Clear
                                    </CButton>
                                </CCol>
                            </CRow>

                            <div className="text-danger">{errors.signatureBase64}</div>

                            <CContainer className='d-flex justify-content-center'>
                                <SignatureCanvas penColor='black' backgroundColor='white'
                                    ref={(ref) => { sigPad = ref }}
                                    onEnd={createSignImg}
                                    maxWidth={2}
                                    minWidth={2}
                                    canvasProps={{ width: 'max', height: 100, className: 'sigCanvas' }} />
                            </CContainer>

                        </CCol>
                        <CCol md='12' xs='12' sm='12' className='mt-4'>
                            <button type="button" class="btn  btn-pill btn-behance btn-block " onClick={() => pay()}><span class="cib-behance btn-icon mr-2"></span> Pay</button>
                        </CCol>
                    </CRow>
                </CContainer>
            </CContainer >
        </BlockUi>
    );



}

const Payed = () => {
    return (<h3>This request was payed</h3>);
}
const PaySuccess = () => {
    return (<h3><b>Success!!!</b> Your payment is being processed</h3>);
}
export { Pay, Payed, PaySuccess };
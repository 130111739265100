import { URL_SERVER } from '../configs/urlConfig';
import { authHeader, handleResponse } from '../_helpers';

export const userService = {
    getAll,
    getById,
    isActivated,
    confirmCodePhone,
    sendCodeConfirmPhone
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/Users/GetAll`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/v1/Users/Get?id=${id}`, requestOptions).then(handleResponse);
}

function isActivated(username) {
    const requestOptions = { method: 'GET' };
    return fetch(`${URL_SERVER}/api/v1/Users/IsActivated?username=${username}`, requestOptions).then(handleResponse);
}

function sendCodeConfirmPhone(phoneNumber) {
    const requestOptions = { method: 'GET' };
    return fetch(`${URL_SERVER}/api/v1/Login/SendCodeConfirmation?phoneNumber=${phoneNumber}`, requestOptions).then(handleResponse);
}

function confirmCodePhone(phoneNumber, code) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            code: code,
            phoneNumber: phoneNumber,
        })
    };
    const url = `${URL_SERVER}/api/v1/Login/ConfirmCodePhone`;
    return fetch(url, requestOptions)
        .then(handleResponse);
}
import { URL_SERVER } from '../configs/urlConfig';
import { authHeader, handleResponse } from '../_helpers';

export const userOfClientService = {
    getClientOfUserAuth
}

function getClientOfUserAuth() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${URL_SERVER}/api/UserOfClient/GetClientAuthenticated`, requestOptions).then(handleResponse);
}
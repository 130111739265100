import { authenticationService } from '../_services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken && currentUser.accessToken.token) {
        return { Authorization: `Bearer ${currentUser.accessToken.token}` };
    } else {
        return {};
    }
}

export function authHeaderTypeJson() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken && currentUser.accessToken.token) {
        return {
            Authorization: `Bearer ${currentUser.accessToken.token}`,
            'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}
export const InvoiceStatus = {
    0: "Pending",
    1: "Started",
}

export const PaymentType = {
    0: "Credit Card",
    1: "Cash"
}



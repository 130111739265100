import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { authenticationService } from '../_services';

export const ProtectedRoute = ({ roles, ...props }) => {

    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }
    var today = new Date();
    var token = currentUser.accessToken?.token;
    var tokenExpiration = new Date(currentUser.accessToken?.expirationUtc);
    if (!token || today > tokenExpiration) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    // check if route is restricted by role
    if (roles && roles.filter(value => currentUser.user.roles.includes(value)).length == 0) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component
    return <Route {...props} />
}

import { URL_SERVER } from '../configs/urlConfig';
import { authHeader, authHeaderTypeJson, handleResponse } from '../_helpers';

export const agencyService = {
    getByNumber,
    getSetting,
    setSetting
}

function getByNumber(number) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${URL_SERVER}/api/v1/Agency/GetByNumber?number=${number}`, requestOptions).then(handleResponse);
}

async function getSetting() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return await fetch(`${URL_SERVER}/api/v1/Agency/GetSetting`, requestOptions).then(handleResponse).then(res => {
        if (res.isSuccess) {
            return res.object;
        }
        else {
            console.log(res);
            return null;
        }
    }, error => {
        console.log(error);
        return null;
    });
}

async function setSetting(serviceCost, interest) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderTypeJson(),
        body: JSON.stringify({
            ServiceCost: serviceCost,
            InterestPercentage: interest
        })
    };
    return await fetch(`${URL_SERVER}/api/v1/Agency/UpdateSetting`, requestOptions).then(handleResponse);
}
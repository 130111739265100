import React, { Component } from 'react';
import { createHashHistory } from 'history';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { ToastProvider } from 'react-toast-notifications';
import { ProtectedRoute } from './_components/ProtectedRoute';
import { Pay, Payed, PaySuccess } from './views/pages/pay/Pay';
import { PDFRenderer, PDFViewer } from '@react-pdf/renderer';
import { InvoicePdf } from './views/pages/invoices/InvoicePdf';
import { PrivateRoute } from './_components'
import { Role } from './_helpers';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const RegisterClient = React.lazy(() => import('./views/pages/register/RegisterClient'));
const ConfirmPhone = React.lazy(() => import('./views/pages/users/ConfirmPhone'));

class App extends Component {

  render() {
    return (
      <ToastProvider>
        <HashRouter history={createHashHistory()}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/:agencyNumber/register" name="Register Client" render={props => <RegisterClient {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route exact path="/pay/:id" name="Pay Request" render={props => <Pay {...props} />} />
              <Route exact path="/payed" name="Payed" render={props => <Payed {...props} />} />
              <Route exact path="/pay_success" name="Pay Success" render={props => <PaySuccess {...props} />} />
              <Route exact path="/confirm_phone/:phone" name="Confirm Phone" render={props => <ConfirmPhone {...props} />} />
              <ProtectedRoute exact path="/invoice_pdf/:id" name="Invoice Pdf" roles={[Role.Admin, Role.Employee]} render={props => <InvoicePdf {...props} />} />
              <ProtectedRoute path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </ToastProvider>

    );
  }
}

export default App;
